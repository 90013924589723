import * as React from "react"
import Seo from "../components/seo"
import { RichText } from 'prismic-reactjs'
import { Link, graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import Copyright from "../components/copyright"
import { GatsbyImage } from "gatsby-plugin-image"

const Home = ({data}) => {
  const entry = data.prismicHomePage
  //console.log('promo: ', entry);
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname="/" />
      <Header />
      <div className="w-full py-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none lg:flex gap-12 items-center justify-between">
            <div className="w-full lg:w-6/12 mb-12 lg:mb-0">
            <GatsbyImage image={entry.data.image.gatsbyImageData} alt={entry.data.heading.text} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
            </div>
            <div className="w-full lg:w-6/12">
              <h1 className="font-ubuntu font-bold text-red text-4xl sm:text-5xl mb-6 leading-tight">{entry.data.heading.text}</h1>
              <div className="font-ubuntu prose prose-xl leading-normal">
                {RichText.render(entry.data.text.richText)}
              </div>
              <div className="font-ubuntu text-2xl text-red border-2 border-red py-2 px-4 mt-12 inline-block">
                <Link to="/contact/">Make enquiry <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-12 border-t-2 border-grey">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex gap-12 justify-between">
            <div className="w-full sm:w-8/12">
              <div className="font-ubuntu font-bold text-red text-4xl sm:text-5xl leading-tight mb-6 sm:mb-12">
                <h2>Our tapes</h2>
              </div>
            </div>
            <div className="w-full sm:w-4/12">
              &nbsp;
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pb-12 border-b-2 border-grey">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
            {entry.data.tapes.map((entry, i) => (
            <div className="group" key={`tape_${i}`}>
              <Link to={entry.tape.url}>
              <div className="relative">
                <div className={`absolute z-10 right-0 px-3 pb-3 center h-full text-white bg-${entry.colour} opacity-1`}>
                  <div className="flex h-full items-end">
                    <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-12 w-12" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                  </div>
                </div>
                <GatsbyImage image={entry.image.gatsbyImageData} alt={entry.heading1.text} className="h-64" style={{ width: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
              </div>
              <div className="font-ubuntu text-black text-2xl leading-tight mt-6 mb-3">
                <h2 className="border-b-2 border-white group-hover:border-b-2 group-hover:border-black inline">{entry.heading1.text}</h2>
              </div>
              </Link>
            </div>
            ))}
          </div>
        </div>
      </div>
      <div className={entry.data.promo ? 'hidden' : ''}>
        <div className="w-full pt-12">
          <div className="w-11/12 mx-auto max-w-screen-2xl">
            <div className="flex-none sm:flex gap-12 justify-between">
              <div className="w-full sm:w-8/12">
                <div className="font-ubuntu font-bold text-red text-4xl sm:text-5xl leading-tight mb-6 sm:mb-12">
                  <h2>{entry.data.promo_tag.text}</h2>
                </div>
              </div>
              <div className="w-full sm:w-4/12">
                &nbsp;
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-red py-12 lg:py-0">
          <div className="w-11/12 mx-auto max-w-screen-2xl">
            <div className="flex-none lg:flex gap-12 justify-between items-center">
              <div className="w-full lg:w-8/12 h-96 mb-12 lg:mb-0">
                <GatsbyImage image={entry.data.promo_image.gatsbyImageData} alt={entry.data.promo_heading.text} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
              </div>
              <div className="w-full lg:w-4/12">
                <div className="font-ubuntu text-white font-bold text-3xl leading-tight mb-3">
                  <h3>{entry.data.promo_heading.text}</h3>
                </div>
                <div className="font-ubuntu prose text-white prose-xl leading-relaxed">
                  {RichText.render(entry.data.promo_text.richText)}
                </div>
                <div className="font-ubuntu text-2xl text-white border-2 border-white py-2 px-4 mt-12 mb-12 lg:mb-0 inline-block">
                  <Link to={entry.data.promo_pagelink.url}>{entry.data.promo_button} <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex gap-12 justify-between">
            <div className="w-full sm:w-8/12">
              <div className="font-ubuntu font-bold text-red text-4xl sm:text-5xl leading-tight mb-6 sm:mb-12">
                <h2>Our services</h2>
              </div>
            </div>
            <div className="w-full sm:w-4/12">
              &nbsp;
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-red py-12 lg:py-0">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none lg:flex gap-12 justify-between items-center">
            <div className="w-full lg:w-4/12">
              <div className="font-ubuntu text-white font-bold text-3xl leading-tight mb-3">
                <h3>{entry.data.services_heading.text}</h3>
              </div>
              <div className="font-ubuntu prose text-white prose-xl leading-relaxed">
                {RichText.render(entry.data.services_text.richText)}
              </div>
              <div className="font-ubuntu text-2xl text-white border-2 border-white py-2 px-4 mt-12 mb-12 lg:mb-0 inline-block">
                <Link to="/services/">Services <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
              </div>
            </div>
            <div className="w-full lg:w-8/12 h-96">
              <GatsbyImage image={entry.data.services_image.gatsbyImageData} alt={entry.data.services_heading.text} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-12 pb-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex gap-12 justify-between">
            <div className="w-full sm:w-8/12">
              <div className="font-ubuntu font-bold text-red text-4xl sm:text-5xl leading-tight">
                <h2>Location</h2>
              </div>
            </div>
            <div className="w-full sm:w-4/12"></div>
          </div>
        </div>
      </div>
      <div className="w-full pb-12">
        <div className="mx-auto max-w-screen-3xl h-96">
          <iframe title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3191.2917602715265!2d174.62809361529227!3d-36.883367979931485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d41d5c80edb3f%3A0x74817ae418a8b845!2sDanco%20NZ%20Ltd!5e0!3m2!1sen!2snz!4v1646943083403!5m2!1sen!2snz" width="100%" height="100%" className="border-0" allowFullScreen="" loading="lazy"></iframe>   
        </div>
      </div>
      <Footer />
      <Copyright />
    </>
  )
}

export const query = graphql`
query {
  prismicHomePage {
    data {
      text {
        richText
      }
      image {
        gatsbyImageData(width: 1024)
      }
      page_title
      meta_description
      heading {
        text
      }
      tapes {
        colour
        heading1 {
          text
        }
        image {
          gatsbyImageData(width: 640, height: 640)
        }
        tape {
          url
        }
      }
      promo
      promo_button
      promo_pagelink {
        url
      }
      promo_tag {
        text
      }
      promo_heading {
        text
      }
      promo_text {
        richText
      }
      promo_image {
        gatsbyImageData(width: 1024)
      }
      services_heading {
        text
      }
      services_text {
        richText
      }
      services_image {
        gatsbyImageData(width: 1024)
      }
    }
  }
}
`

export default Home
